import Grid from '@material-ui/core/Grid';
import {useState, useEffect} from 'react';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import  milan_logo from './milan_logo_white.png';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
//import React from 'react'
function Dashboard() {

const [salesInfo, setSalesInfo] = useState({SoldPercent:0, TotalBookedConsults:0, TotalCompletedConsults:0, TotalSales:0, AverageNewSale:0, BuyBackPercent:0, SameDayPercent:0, TotalGoals:0 });
const [goalPercent, setGoalPercent] = useState(0);
useEffect(()=>{    
    getSalesInfo()
},[])    

setInterval(()=>{
    getSalesInfo();
}, 1000 * 60* 5);

const getSalesInfo = () => {
    axios.get('https://cors-milanlaser.herokuapp.com/https://milan-laser.secure.force.com/getsalesdashboardinfo/services/apexrest/getsalesdashboardinfo')
    .then(res=>{
        setSalesInfo(res.data);
        //console.log(res.data);
        (res.data.TotalSales > 0 && res.data.TotalGoals > 0) && setGoalPercent(Math.round((res.data.TotalSales/res.data.TotalGoals)*100))
    })
}
const font =  "'Open Sans', sans-serif";


    return (
        <div style={{}}>
        <div style={{display:'flex', flexDirection:'row', marginTop:30, marginLeft:'5%', textAlign:'left'}}>
        <img src={milan_logo} style={{ width:247, height:105}} alt="Milan Logo" />
        <Typography variant="h3" style={{color:'white', marginLeft:15,marginTop:'-0.3%',fontFamily:font}}><span style={{fontWeight:"600"}}>MONTHLY SALES</span><br/> DASHBOARD</Typography>
        </div>
       <Grid container style={{marginTop:35, justifyContent:'center'}}>
       <Grid item md={5} style={{border: 1,marginLeft:10, backgroundColor:'white', borderColor:'#003a44', borderRadius:10, borderStyle: 'solid', padding:20, borderLeft:'none'}}>
       <Typography variant="h3" style={{marginBottom:25,fontFamily:font, fontWeight:"400"}}>Total Sales (This Month)</Typography>
       {/*<Progress type="circle" width={400}
       theme={{
           success: {
             color: 'green',
             trailColor:'limegreen'
                    },
            active: {
             color: goalPercent > 75 ? 'green' : '#01718f',
             trailColor:goalPercent > 75 ? 'limegreen' : '#8cdfe6'
                    }
    }} 
percent={goalPercent} />*/}
<center><div style={{width:'70%'}}>
<CircularProgressbar
        value={goalPercent}
        text={`${goalPercent}%`}
        background
        backgroundPadding={6}
        styles={buildStyles({
          backgroundColor: "#01718f",
          textColor: "#fff",
          pathColor: "#fff",
          trailColor: "transparent"
        })} />
</div></center>
       <Typography className="number-text" style={{marginTop:20, fontWeight:"4",fontFamily:font,fontWeight:"800"}} variant="h1"><sup style={{fontSize:55}}>$</sup><NumberFormat value={salesInfo.TotalSales} displayType={'text'} thousandSeparator={true}/></Typography>
       </Grid>
       <Grid item md={3} style={{marginLeft:7, marginRight:7}}>
       <div style={{height:'32.5%', width:'100%', border: 1, backgroundColor:'white', borderColor:'#003a44', borderRadius:10}}>
       <Typography variant="h4" style={{paddingTop:'10%',fontFamily:font, fontWeight:"400"}}>Company wide Sold %</Typography>
       <Typography style={{fontWeight:"800", fontFamily:font}} className="number-text" variant="h1">{salesInfo.SoldPercent}<sup style={{fontSize:55}}>%</sup></Typography>
       </div>
  
       <div style={{height:'32.5%', width:'100%',border: 1, backgroundColor:'white', borderColor:'#003a44',marginTop:'2%', marginBottom:'2%', borderRadius:10, alignItems:'center', justifyContent:'center'}}>
       <Typography variant="h4" style={{paddingTop:'10%',fontFamily:font, fontWeight:"400"}}>Average New Sale</Typography>
       <Typography style={{fontWeight:"800",fontFamily:font}} className="number-text" variant="h1"><sup style={{fontSize:55}}>$</sup><NumberFormat value={salesInfo.AverageNewSale} displayType={'text'} thousandSeparator={true}  /></Typography>
       </div>
       <div style={{height:'32.5%', width:'100%',border: 1, backgroundColor:'white', borderColor:'#003a44', borderRadius:10}}>
       <Typography variant="h4" style={{paddingTop:'10%',fontFamily:font, fontWeight:"400"}}>Completed Consults</Typography>
       <Typography style={{fontWeight:"800",fontFamily:font}} className="number-text" variant="h1"><NumberFormat value={salesInfo.TotalCompletedConsults} displayType={'text'} thousandSeparator={true}/></Typography>
       </div>
       </Grid>
       <Grid item md={3} style={{}}>
       <div style={{height:'32.5%', width:'100%',border: 1, backgroundColor:'white', borderColor:'#003a44', borderRadius:10}}>
       <Typography variant="h4" style={{paddingTop:'10%',fontFamily:font, fontWeight:"400"}}>Same Day Sold %</Typography>
       <Typography style={{fontWeight:"800",fontFamily:font}} className="number-text" variant="h1">{salesInfo.SameDayPercent}<sup style={{fontSize:55}}>%</sup></Typography>
       </div>
  
       <div style={{height:'32.5%', width:'100%',border: 1, backgroundColor:'white',marginTop:'2%', marginBottom:'2%', borderColor:'#003a44', borderRadius:10}}>
       <Typography variant="h4" style={{paddingTop:'10%',fontFamily:font, fontWeight:"400"}}>Buy Back Sold %</Typography>
       <Typography style={{fontWeight:"800",fontFamily:font}} className="number-text" variant="h1">{salesInfo.BuyBackPercent}<sup style={{fontSize:55}}>%</sup></Typography>
       </div>
       <div style={{height:'32.5%', width:'100%',border: 1, backgroundColor:'white', borderColor:'#003a44', borderRadius:10}}>
       <Typography variant="h4" style={{paddingTop:'10%',fontFamily:font, fontWeight:"400"}}>Booked Consults</Typography>
       <Typography style={{fontWeight:"800",fontFamily:font}} className="number-text" variant="h1"><NumberFormat value={salesInfo.TotalBookedConsults} displayType={'text'} thousandSeparator={true}/></Typography>
       </div>
       </Grid>
       
       </Grid>
       </div>
    )
}

export default Dashboard
