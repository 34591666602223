import './App.css';


import Dashboard from './Dashboard';

function App() {

var url_string = window.location.href
var url = new URL(url_string);
var token = url.searchParams.get("token");

  return (
    <div className="App">
    
   {token === '7663' ? <Dashboard/> : <h1>You Do Not have access to this Dashboard</h1>}
    </div>
  );
}

export default App;
